import { template as template_70de232ecf9f4dfc910836b849316fa4 } from "@ember/template-compiler";
const FKText = template_70de232ecf9f4dfc910836b849316fa4(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
