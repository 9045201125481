import { template as template_329443569b574ed6b8dff744eeab946f } from "@ember/template-compiler";
const WelcomeHeader = template_329443569b574ed6b8dff744eeab946f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
