import { template as template_3b4f727f35a04250a19a17f9c9a2b41c } from "@ember/template-compiler";
const FKLabel = template_3b4f727f35a04250a19a17f9c9a2b41c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
